<template>
  <div class="page-container">
    <div class="content-wrap">
    <TopNavBar
      v-bind:secondTierLoadingDone="true"/>

    <b-container>
      <b-row class="mt-4" align-h="center" >
        <b-col class="w-100">
          <b-card-header>
          </b-card-header>
          <b-card class = "list-group p-1 mt-3">
            <b-row>
            <h1 class="pl-3">{{$t('Lektorat')}}</h1>
              <span class="text-muted small p-3">{{$t('This is a playground to test the limits of the grammar engine \'ParZu\' that is running in the background. For productive use, please download our Office Add-ins.')}}</span>
              <ul class="w-100 list-group list-group-horizontal-md">
                <li class="w-100 list-group-item active">
                  <h4>
                    {{$t('Your text')}}
                  </h4>
                    <textarea
                      class="w-100 fixed-hight invisible-scrollbar"
                      id="running_text"
                      name="running_text"
                      v-model="running_text"></textarea>
                  <small class="form-text text-muted"
                         v-if="!$v.running_text.required">
                    {{$t('Running text should not empty')}}
                  </small>
                  <div class="mt-2">
                    <b-button class="mr-3"
                              type="button"
                              variant="primary"
                              v-on:click="fixRunningText">
                              {{$t('Fix')}}</b-button>
                  </div>
                </li>
                <li class="w-100 list-group-item">
                  <h4 class="text-primary">
                    {{$t('gender neutral')}}
                  </h4>
                  <span class="w-100 d-inline-block fixed-hight bg-primary text-white p-2 invisible-scrollbar">
                  <text-highlight
                    :queries="queries"
                    class="w-100"
                    style="white-space: pre-line !important;
                           word-wrap: break-word !important;">
                    {{ fixedText }}
                  </text-highlight>
                  </span>
          <div v-if="tooLong">
            <b-alert show variant="warning"  v-html="$t('Only 500 characters are processed. For longer texts please use the word add-in.')">
            </b-alert>
          </div>
                </li>
            </ul>
            </b-row>
          </b-card>
          <div v-if="error">
            <b-alert show variant="danger">
              {{error}}
            </b-alert>
          </div>

          <div v-if="success">
            <b-alert show variant="success">
              {{success}}
            </b-alert>
          </div>
        </b-col>
      </b-row>
<!--register modal-->
      <div>
      <b-modal hide-footer ref="registerModal" v-bind:title="$t('you are not logged in')">
        <div class="d-block text-center">
          <small class="text-muted">{{$t('translate only works while logged in')}}</small>
          <h3 class="pt-4">{{$t('wanna create an account?')}}</h3>
        </div>
        <router-link :to="{name: 'auth.register'}" class="mt-2 btn btn-outline-success btn-block">{{$t("Go to register")}}</router-link>
        <router-link :to="{name: 'auth.login'}" class="mt-2 btn btn-outline-info btn-block">{{$t("Go to login")}}</router-link>
        <b-button block @click="hideRegisterModal" class="mt-2 btn btn-outline-danger btn-block">{{$t("Cancel")}}</b-button>
      </b-modal>
    </div>
    </b-container>
  </div>
    <GenderAppFooter/>
  </div>
</template>

<script>
import TopNavBar from '../../components/TopNavBar'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { DictionaryEntryService } from '../../services/dictionaryEntryService'
import { modalMixin } from '../../mixins/modalMixin'
import GenderAppFooter from '../../components/Footer'

export default {
  name: 'Solution',
  // insert the following code for vue-meta to work
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          vmid: 'description',
          content: this.description
        },
        {
          property: 'og:title',
          vmid: 'og:title',
          content: this.title
        },
        {
          property: 'og:description',
          vmid: 'og:description',
          content: this.description
        },
        {
          property: 'og:url',
          vmid: 'og:url',
          content: this.canonicalUrl
        },
        {
          name: 'twitter:title',
          vmid: 'twitter:title',
          content: this.title
        },
        {
          name: 'twitter:description',
          vmid: 'twitter:description',
          content: this.description
        }
      ],
      link: [
        { rel: 'canonical', href: this.canonicalUrl }
      ]
    }
  },
  data () {
    return {
      queries: [],
      running_text: '',
      fixedText: '',
      lektoratWaiting: 0,
      lektoratWorking: 0,
      error: '',
      success: '',
      text_cache: '',
      title: 'gender app translate · gender app',
      description: 'Dies ist der experimentelle Spielplatz, auf welchem die Grammatik-Engine ihre Grenzen testet.',
      canonicalUrl: 'https://genderapp.org/translate'
    }
  },
  computed: {
    tooLong: function () {
      return this.running_text.length >= 500
    }
  },
  mixins: [validationMixin, modalMixin],
  validations: {
    running_text: {
      required
    }
  },
  methods: {

    cleanUpString (inputString, allowedCharacters = '[^a-zA-Z0-9öäüÖÄÜ\\s\\,\\.\\;\\n\\-\\?\\!\\*\\:\\/\\_]') {
      const regex = new RegExp('[^' + allowedCharacters + ']', 'g')
      return inputString.replace(regex, ' ')
    },
    sendLektorat: async function () {
      if (this.lektoratWaiting > 0) return Promise.resolve('Too many cooks')
      this.lektoratWaiting++
      await this.waitForSilence(0.1)
      this.lektoratWaiting--
      this.lektoratWorking++
      if (!this.running_text.toString().trim()) {
        this.fixedText = ''
        this.lektoratWorking--
        return Promise.resolve('empty paragraph')
      }
      await this.fixRunningText()
      this.lektoratWorking--
      return Promise.resolve('ACK')
    },
    waitForSilence: async function (secs) {
      let noOfSecsWeWait = 0.2
      if (secs) {
        noOfSecsWeWait = secs
      }
      let disturbed = true
      while (disturbed) {
        disturbed = false
        await wait(noOfSecsWeWait * 1000)
        if (this.text_cache !== this.running_text) {
          this.text_cache = this.running_text
          disturbed = true
        }
      }
      return Promise.resolve('got it')
    },
    fixRunningText: function () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.error = this.$t('Please check above error(s).')
      } else {
        if (!this.isLoggedIn()) {
          this.showRegistrationModal()
          return
        }
        const cleanTruncRunningtext = this.cleanUpString(this.running_text.substring(0, 500))
        DictionaryEntryService.fixRunningText(cleanTruncRunningtext, ['N', 'V', 'ADJ', 'ART', 'PRO'], ['entries', 'fixedText'])
          .then((response) => {
            // console.log(response)
            const newQueries = []
            const messageFromBackend = JSON.parse(response.data.result)
            this.fixedText = messageFromBackend.wordReplacer.fixedText
            messageFromBackend.wordReplacer.entries.forEach(entry => {
              const alternatives = entry.alternatives
              if (Array.isArray(alternatives) && alternatives.length) {
                alternatives.forEach(alternative => {
                  newQueries.push(alternative.alternative)
                })
              }
            })
            this.queries = newQueries
            this.error = ''
          }
          )
          .catch((error) => {
            this.error = error
            this.error = ''
            this.success = ''
          })
      }
    },
    showRegistrationModal () {
      this.$refs.registerModal.show()
    },
    hideRegistrationModal () {
      this.$refs.registerModal.hide()
    }
  },
  watch: {
    running_text: function () {
      this.sendLektorat()
    }
  },
  mounted () {
    if (!this.isLoggedIn()) {
      this.showRegistrationModal()
    }
  },
  components: { TopNavBar, GenderAppFooter }
}
async function wait (ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}
</script>

<style scoped>

.fixed-hight{
    height: 30vh;
    border-radius: 0.25rem;
    word-break: break-word;
  overflow-y:scroll;
}

.invisible-scrollbar::-webkit-scrollbar {
    display: none !important;
  }
  >>>.text__highlight{
    background: rgba(35, 182, 78, 0.66);
    color: white;
    padding: 1px !important;
  }
</style>
